import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import {
  Container,
  Grid,
  Box,
  Text,
  Flex,
  Image,
  Heading,
  Button,
  Select,
  Link,
} from "theme-ui"
import Layout from "../components/layout"
import { getPagePath } from "../utils/path"
import BlogTitle from "../components/blogTitle"
import { HelmetDatoCms } from "gatsby-source-datocms"
import FilterMetaTagDescription from "../utils/filterMetaTags"
import linkSwitch from "../utils/linkSwitch"
import countryIcon from "../images/event-country-icon.svg"
import locationIcon from "../images/event-location-icon.svg"

const EventPage = ({ data: { page } }) => {
  const i18nPaths = page._allSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })
  const [events, setEvents] = useState([]) // Initialize events state

  useEffect(() => {
    const fetchAllEvents = async () => {
      let skip = 0
      const limit = 100 // Number of events to fetch per request

      const response = await fetch("https://graphql.datocms.com/", {
        method: "POST",
        headers: {
          Authorization: "f25bcd9fd15c432e5989692391c812", // Your API token
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `{
              allEvents(first: ${limit}, skip: ${skip}) {
                id
                name
                startDate
                endDate
                country
                location
                hallBooth
                image {
                  url
                }
                url
              }
            }`,
        }),
      })

      const { data } = await response.json() // Parse the JSON response

      setEvents(data.allEvents) // Set events state with all fetched events
      // console.log(data.allEvents)
    }

    fetchAllEvents() // Call the fetch function
  }, [])

  const today = new Date()
  const [pastEvents, setPastEvents] = useState([]) // Initialize pastEvents state
  const [futureEvents, setFutureEvents] = useState([]) // Initialize futureEvents state

  useEffect(() => {
    setPastEvents(
      events
        .filter(event => new Date(event.endDate) < today)
        .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    )
    setFutureEvents(
      events
        .filter(event => new Date(event.endDate) > today)
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    )
  }, [events]) // Re-run this effect whenever events changes

  const [nextEvent, setNextEvent] = useState()
  const [eventType, setEventType] = useState("future") // Initialize eventType state

  useEffect(() => {
    setNextEvent(futureEvents[0])
  }, [futureEvents]) // Re-run this effect whenever futureEvents changes

  const handleTabChange = type => {
    setEventType(type)
  }

  const [selectedCountry, setSelectedCountry] = useState("")
  const [filteredOtherEvents, setFilteredOtherEvents] = useState([]) // Initialize filteredOtherEvents state
  const [filteredPastEvents, setFilteredPastEvents] = useState(pastEvents)

  useEffect(() => {
    const filterEvents = eventList => {
      if (selectedCountry === "") {
        return eventList
      } else {
        return eventList.filter(fair => fair.country === selectedCountry)
      }
    }

    // Update filteredOtherEvents based on futureEvents and selectedCountry, excluding nextEvent
    setFilteredOtherEvents(
      filterEvents(futureEvents.filter(event => event.id !== nextEvent?.id))
    )
    setFilteredPastEvents(filterEvents(pastEvents))
  }, [futureEvents, pastEvents, selectedCountry, nextEvent]) // Re-run this effect whenever futureEvents, pastEvents, selectedCountry, or nextEvent changes

  useEffect(() => {
    setSelectedCountry("")
  }, [eventType])

  const formatDateRange = (startDate, endDate, locale) => {
    const options = { day: "2-digit", month: "short", year: "numeric" }
    const start = new Intl.DateTimeFormat(locale, options).format(
      new Date(startDate)
    )
    const end = new Intl.DateTimeFormat(locale, options).format(
      new Date(endDate)
    )
    return start === end ? start : `${start} - ${end}`
  }

  return (
    <Layout
      locale={page.locale}
      i18nPaths={i18nPaths}
      theme={"dark"}
      hideMenu={true}
    >
      <HelmetDatoCms seo={FilterMetaTagDescription(page.seoMetaTags)}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Container sx={{ mt: [8, "16rem"] }}>
        <BlogTitle page={page} />
        {nextEvent && (
          <NextEvent
            formatDateRange={formatDateRange}
            nextEvent={nextEvent}
            page={page}
          />
        )}
      </Container>
      <Container sx={{}}>
        <Flex
          sx={{
            borderTop: "1px solid",
            borderColor: "dark",
            mt: 4,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => handleTabChange("future")}
            sx={{
              color: eventType === "future" ? "primary" : "dark",
              bg: "white",
              mt: "-1px",
              borderTop: "1px solid",
              borderColor: eventType === "future" ? "primary" : "dark",
              borderRadius: 0,
              pt: 3,
              pl: 0,
              pr: 5,
              fontFamily: "heading",
              fontSize: 3,
              "&:hover": {
                color: "primary",
                bg: "white",
              },
            }}
          >
            Upcoming Events
          </Button>
          <Button
            onClick={() => handleTabChange("past")}
            sx={{
              color: eventType === "past" ? "primary" : "dark",
              bg: "white",
              mt: "-1px",
              borderTop: "1px solid",
              borderColor: eventType === "past" ? "primary" : "dark",
              borderRadius: 0,
              pt: 3,
              pl: 0,
              pr: 5,
              fontFamily: "heading",
              fontSize: 3,
              "&:hover": {
                color: "primary",
                bg: "white",
              },
            }}
          >
            Past Events
          </Button>
        </Flex>
      </Container>
      {eventType === "future" && filteredOtherEvents.length > 0 && (
        <EventContainer
          events={filteredOtherEvents}
          page={page}
          formatDateRange={formatDateRange}
        />
      )}
      {eventType === "past" && filteredPastEvents.length > 0 && (
        <EventContainer
          events={filteredPastEvents}
          page={page}
          formatDateRange={formatDateRange}
        />
      )}
    </Layout>
  )
}

export default EventPage

const NextEvent = ({ nextEvent, page, formatDateRange }) => (
  <>
    <Heading
      as="h2"
      sx={{
        mb: [4],
        color: "dark",
        fontSize: [3],
        borderTop: "1px solid",
        borderColor: "dark",
        pt: 3,
      }}
    >
      Up Next
    </Heading>
    <Grid columns={[1, 1, 1, 2]} gap={0} sx={{ backgroundColor: "dark" }}>
      <Flex
        sx={{
          color: "light",
          padding: 4,
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Text as="p" sx={{ textTransform: "uppercase", fontSize: [1] }}>
          {formatDateRange(nextEvent.startDate, nextEvent.endDate, page.locale)}{" "}
          {nextEvent.hallBooth && (
            <Text
              sx={{ color: "secondary" }}
            >{`• ${nextEvent.hallBooth}`}</Text>
          )}
        </Text>
        <Text
          as="p"
          sx={{
            fontSize: [6],
            mt: 3,
            mb: 3,
            lineHeight: 1.2,
          }}
        >
          {nextEvent.name}
        </Text>
        {nextEvent.location && (
          <Flex sx={{ alignItems: "flex-end", mb: [2] }}>
            <Image src={locationIcon} sx={{ mr: [2] }} />
            <Text
              as="p"
              sx={{
                textTransform: "uppercase",
                fontSize: [1],
                lineHeight: 1,
                color: "secondary",
              }}
            >
              {nextEvent.location}
            </Text>
          </Flex>
        )}
        {nextEvent.country && (
          <Flex
            sx={{
              alignItems: "flex-end",
            }}
          >
            <Image src={countryIcon} sx={{ mr: [2] }} />
            <Text
              as="p"
              sx={{
                textTransform: "uppercase",
                fontSize: [1],
                lineHeight: 1,
                color: "secondary",
              }}
            >
              {nextEvent.country}
            </Text>
          </Flex>
        )}
        {nextEvent.url && (
          <Box sx={{ mt: 4, a: { color: "white" } }}>
            <Link
              href={nextEvent.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Discover more
            </Link>
          </Box>
        )}
      </Flex>
      <Box
        sx={{
          img: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        }}
      >
        <Image src={nextEvent.image.url} />
      </Box>
    </Grid>
  </>
)

const EventContainer = ({ events, page, formatDateRange }) => {
  const [selectedCountry, setSelectedCountry] = useState("")

  // Get unique countries from events
  const countries = Array.from(
    new Set(events.map(event => event.country))
  ).sort()

  // Handle country selection
  const handleCountryChange = event => {
    setSelectedCountry(event.target.value)
  }

  // Filter events based on selected country
  const filteredEvents = selectedCountry
    ? events.filter(event => event.country === selectedCountry)
    : events

  return (
    <Container sx={{ pt: [0, 0, 0, 0] }}>
      <Box sx={{ mb: 4 }}>
        <Select
          onChange={handleCountryChange}
          value={selectedCountry}
          sx={{ fontFamily: "heading" }}
        >
          <option value="">All Countries</option>
          {countries.map(country => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </Select>
      </Box>
      <Grid columns={[1, 1, 1, 2]} gap={32}>
        {filteredEvents.map(fair => (
          <Event
            formatDateRange={formatDateRange}
            fair={fair}
            page={page}
            key={fair.id}
          />
        ))}
      </Grid>
    </Container>
  )
}

const Event = ({ fair, page, formatDateRange }) => (
  <Grid columns={[1, 1, 2]} gap={0}>
    <Box
      sx={{
        border: "1px solid",
        borderColor: "#dedede",
        borderRight: "none",
        img: {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      }}
    >
      <Image src={fair.image.url} alt={fair.name} />
    </Box>
    <Box
      sx={{
        backgroundColor: "dark",
        color: "light",
        padding: 4,
      }}
    >
      <Text as="p" sx={{ textTransform: "uppercase", fontSize: [1] }}>
        {formatDateRange(fair.startDate, fair.endDate, page.locale)}
      </Text>
      {fair.hallBooth && (
        <Text
          as="p"
          sx={{
            textTransform: "uppercase",
            fontSize: [1],
            color: "secondary",
          }}
        >
          {fair.hallBooth}
        </Text>
      )}
      <Text
        as="p"
        sx={{
          fontSize: [4],
          pt: 3,
          mt: 3,
          mb: 3,
          borderTop: "1px solid white",
        }}
      >
        {fair.name}
      </Text>
      {fair.location && (
        <Flex sx={{ alignItems: "flex-end", mb: [2] }}>
          <Image src={locationIcon} sx={{ mr: [2] }} />
          <Text
            as="p"
            sx={{
              textTransform: "uppercase",
              fontSize: [1],
              lineHeight: 1,
              color: "secondary",
            }}
          >
            {fair.location}
          </Text>
        </Flex>
      )}
      {fair.country && (
        <Flex
          sx={{
            alignItems: "flex-end",
          }}
        >
          <Image src={countryIcon} sx={{ mr: [2] }} />
          <Text
            as="p"
            sx={{
              textTransform: "uppercase",
              fontSize: [1],
              lineHeight: 1,
              color: "secondary",
            }}
          >
            {fair.country}
          </Text>
        </Flex>
      )}
      {fair.url && (
        <Box sx={{ mt: 4, a: { color: "white" } }}>
          <Link href={fair.url} target="_blank" rel="noopener noreferrer">
            Discover more
          </Link>
        </Box>
      )}
    </Box>
  </Grid>
)

export const query = graphql`
  query EventPageQuery($id: String!, $locale: String!) {
    page: datoCmsEventPage(id: { eq: $id }) {
      ...EventPageDetails
    }
    events: allDatoCmsEvent(
      sort: { fields: startDate, order: DESC }
      filter: { locale: { eq: $locale } }
    ) {
      nodes {
        id
        name
        startDate(locale: "en", formatString: "DD MMMM Y")
        endDate(locale: "en", formatString: "DD MMMM Y")
        country
        location
        hallBooth
        image {
          url
        }
        link {
          ... on DatoCmsExternalLink {
            id: originalId
            anchor
            url
          }
          ... on DatoCmsInternalLink {
            id: originalId
            anchor
            locale
            model {
              apiKey
            }
            link {
              ... on DatoCmsEventPage {
                ...EventPageDetails
              }
              ... on DatoCmsBlogPage {
                ...BlogDetails
              }
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
              ... on DatoCmsArticle {
                ...ArticleDetails
                ...ArticleAllSlugLocales
              }
              ... on DatoCmsArticleCategory {
                ...ArticleCategoryDetails
                ...ArticleCategoryAllSlugLocales
              }
            }
          }
        }
      }
    }
    site: datoCmsSite {
      locales
    }
  }

  fragment EventPageDetails on DatoCmsEventPage {
    id
    locale
    title
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    _allSlugLocales {
      value
      locale
    }
    model {
      apiKey
    }
  }
`
